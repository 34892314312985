// Hero
.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4rem;
  padding-top: 4rem;

  &.hero-sm {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  &.hero-lg {
    padding-bottom: 8rem;
    padding-top: 8rem;
  }

  .hero-body {
    padding: $layout-spacing;
  }
}