// Tabs
.tab {
  align-items: center;
  border-bottom: $border-width solid $border-color;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: $unit-1 0 ($unit-1 - $border-width) 0;

  .tab-item {
    margin-top: 0;

    a {
      border-bottom: $border-width-lg solid transparent;
      color: inherit;
      display: block;
      margin: 0 $unit-2 0 0;
      padding: $unit-2 $unit-1 $unit-2 - $border-width-lg $unit-1;
      text-decoration: none;
      &:focus,
      &:hover {
        color: $link-color;
      }
    }
    &.active a,
    a.active {
      border-bottom-color: $primary-color;
      color: $link-color;
    }

    &.tab-action {
      flex: 1 0 auto;
      text-align: right;
    }

    .btn-clear {
      margin-top: -$unit-1;
    }
  }

  &.tab-block {
    .tab-item {
      flex: 1 0 0;
      text-align: center;

      a {
        margin: 0;
      }

      .badge {
        &[data-badge]::after {
          position: absolute;
          right: $unit-h;
          top: $unit-h;
          transform: translate(0, 0);
        }
      }
    }
  }

  &:not(.tab-block) {
    .badge {
      padding-right: 0;
    }
  }
}
