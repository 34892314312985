// Navs
.nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: $unit-1 0;

  .nav-item {
    a {
      color: $gray-color-dark;
      padding: $unit-1 $unit-2;
      text-decoration: none;
      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
    &.active {
      & > a {
        color: darken($gray-color-dark, 10%);
        font-weight: bold;
        &:focus,
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  & .nav {
    margin-bottom: $unit-2;
    margin-left: $unit-4;
  }
}
