// Labels
.label {
  @include label-base();
  @include label-variant(lighten($body-font-color, 5%), $bg-color-dark);
  display: inline-block;

  // Label rounded
  &.label-rounded {
    border-radius: 5rem;
    padding-left: .4rem;
    padding-right: .4rem; 
  }

  // Label colors
  &.label-primary {
    @include label-variant($light-color, $primary-color);
  }

  &.label-secondary {
    @include label-variant($primary-color, $secondary-color);
  }

  &.label-success {
    @include label-variant($light-color, $success-color);
  }

  &.label-warning {
    @include label-variant($light-color, $warning-color);
  }

  &.label-error {
    @include label-variant($light-color, $error-color);
  }
}
