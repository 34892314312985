// Panels
.panel {
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;

  .panel-header,
  .panel-footer {
    flex: 0 0 auto;
    padding: $layout-spacing-lg;
  }

  .panel-nav {
    flex: 0 0 auto;
  }

  .panel-body {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 0 $layout-spacing-lg;
  }
}
